import alertify from "alertifyjs";
// import bowser from "bowser";
// import * as RoundwareWebFramework from "roundware-web-framework";
import { initRecording } from "./recording";
import { isMobile, isAndroid, isiOS, isChrome, isSafari, browser } from "./responsive";
import { roundware } from "./roundware";
import { CoronaDiariesMapStyle } from "./map-style";
var $ = window.jQuery = require("jquery");
require("gasparesganga-jquery-loading-overlay");

// BROWSER SENSING
console.log(browser);
// First sense mobile/desktop, then sense platform
if (isMobile) {
  console.log("Mobile/tablet device detected");
  // $("#menu-listen").hide();
  if (isiOS && !isSafari) {
    console.log("iOS not using Safari detected");
    const message = "Sorry the browser you’re using isn’t supported yet. Some of our features might not work as intended. We recommend you switch to Safari."
    alertify.alert('<strong>Corona Diaries Warning</strong>', message, function() {
      // alertify.message('OK');
    }).set({
      transition: 'zoom',
      closable: false,
      defaultFocusOff: true
    });
  } else if (isAndroid && !isChrome) {
    console.log("Mobile Android browser detected");
    const message = "Sorry the browser you’re using isn’t supported yet. Some of our features might not work as intended. We recommend you switch to Chrome."
    alertify.alert('<strong>Corona Diaries Warning</strong>', message, function() {
      // alertify.message('OK');
    }).set({
      transition: 'zoom',
      closable: false,
      defaultFocusOff: true
    });
  } else {
    console.log("Other mobile device detected");
  }
} else {
    console.log("Desktop device detected");
    // think we work on safari/firefox and chrome now - probably don't need this.
    /*
    if (isChrome) {
      console.log("Chrome desktop detected");
    } else {
      console.log("Non-chrome desktop detected");
      const message = "Sorry the browser you’re using isn’t officially supported yet. Some of our features might not work as intended. We recommend you switch to Chrome."
      alertify.alert('<strong>Corona Diaries Warning</strong>', message, function(){
      }).set({
        transition:'zoom',
        closable: false,
        defaultFocusOff: true
      });
    }
    */
}

// Safari restrictions on BOTH mobile and desktop
if (isSafari) {
    console.log("Safari browser detected");
    $("#inputmeter").hide();
}

if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
  // check for existing audio access
  navigator.mediaDevices.enumerateDevices()
  .then(function(devices) {
    let audioAllowed = false;
    devices.forEach(function(device) {
      if (device.kind === "audioinput" && device.label) {
        audioAllowed = true;
      }
      console.log(device.kind + ": " + device.label + " id = " + device.deviceId);
    });
    if (audioAllowed) {
      reportSpeakEvent('existingAudio');
      console.log("have existing audio input");
      $("#microphonesection").hide();
      $("#recordInstructions").hide();
      initRecording(roundware);
      startRecordButton.disabled = false;
    } else {
      reportSpeakEvent('noExistingAudio');
      console.log('No existing audio input');
      $("#microphonesection").show();
      $("#recordInstructions").show();
    }
  })
  .catch(function(err) {
    reportSpeakEvent('audioError');
    console.log(err.name + ": " + err.message);
    $("#microphonesection").show();
    $("#recordInstructions").show();
  });
} else {
  reportSpeakEvent('enumerationError');
  console.log("can't enumerate devices");
  $("#microphonesection").show();
  $("#recordInstructions").show();
}

// $("#uiSpeakDisplay").LoadingOverlay("show", {
//     background: "rgba(0, 0, 0, 0.7)",
//     text: "Loading questions...",
//     textColor: "#cccccc",
//     imageColor: "#cccccc",
//     maxSize: "80px"
// });

// local development
// var roundwareServerUrl = "http://localhost:8888/api/2";
// deployment
// var roundwareServerUrl = "https://prod.roundware.com/api/2";
// var roundwareProjectId = 31; // corresponds to a project setup in the Roundware server developer seed script

var streamPlayer, audioSource, pauseButton, playButton, killButton,
  skipButton, replayButton, tagIds, recordButton, setBrowserLocation;
var assetMarkers = [];
var listenMap, speakMap;
var firstplay = false; // ultimately will be set to true initially to handle iOS playback properly
var use_listener_range = false;
var listener_circle_max, listener_circle_min;

window.onbeforeunload = function() { window.scrollTo(0, 0); }

microphoneAccessButton.addEventListener("click", () => {
  reportSpeakEvent('clickRequestAudio');
  navigator.mediaDevices.getUserMedia({ audio: true })
    .then(audiostream => {
      reportSpeakEvent('grantAudio');
      console.log("Got audio access")
      $("#microphonesection").hide();
      $("#audiosuccesssection").show();
      $("#recordInstructions").hide();
      initRecording(roundware);
      startRecordButton.disabled = false;
    })
    .catch(err => {
      reportSpeakEvent('errorAudioAccess-' + err.name);
      console.log("Failed to get audio access, err " + err)
      if (err.name === "NotAllowedError") {
        $("#microphonesection").hide();
        $("#audiodeniedsection").show();
        startRecordButton.disabled = true;
      } else {
        $("#microphonesection").hide();
        $("#audioerrorsection").show();
        $("#audioerrordetail").text("The error is: " + err.name + ", " + err.message)
        startRecordButton.disabled = true;
      }
    });
});

changeLocationButton.addEventListener("click", () => {
  reportSpeakEvent('changeLocation');
  $("#locationSection").hide();
  $("#mapSection").show();
})

function reportSpeakEvent(eventTag) {
  try {
    if (typeof gtag === 'function') {
      gtag('event', eventTag);
      console.log('log speak event ' + eventTag);
    } else if (typeof a === 'function') {
      a('event', eventTag);
      console.log('log speak event ' + eventTag);
    } else {
      console.log('no gtag function defined. failed to log speak event ' + eventTag)
    }
  }
  catch (err) {
    console.log("can't log speak event: " + err);
  }
}

function ready() {
  console.info("Connected to Roundware Server. Ready to play.");
  $("#sessionId").text(roundware._sessionId);
  // $("#uiSpeakDisplay").LoadingOverlay("hide", true);

  // playButton.prop("disabled",false);
  // playButton.click(play);
  // pauseButton.click(pause);
  // killButton.click(kill);
  // replayButton.click(replay);
  // skipButton.click(skip);
  // updateButton.click(update);

  // displayListenTags();
  displaySpeakTags();
  // setupListenMap();
  setupSpeakMap();

}


function displaySpeakTags() {
  console.log(roundware.uiConfig.speak);
  let speakUi = roundware.uiConfig.speak;
  $.each(speakUi, function(index, element) {
    console.log(index + ": " + element.header_display_text);
    // let str = "<h4>" + (index + 1) + " - ";
    let groupShortName = element.group_short_name;
    let order = index;
    let str = "";
    if (index == 0) {
      str = `<h4 category=${groupShortName}>`;
      str += `${element.header_display_text}</h4>`;
    } else {
      str = `<h4 style="display:none" category=${groupShortName}>`;
      str += `${element.header_display_text}</h4>`;
    }
    str += "<form>";
    $.each(element.display_items, function(index, element) {
      let checked = "";
      if (element.default_state) {
        checked = "checked";
      }
      str += `<div class="col-6 col-12-small" `;
      if (order != 0) {
        // if (element.parent_id != null && order != 0) {
        console.log(`order = ${order}`);
        str += `style="display:none">`;
      } else {
        str += `>`;
      }
      str += `<input type="radio" class="uiitem" `;
      str += `name=${groupShortName} `;
      str += `uigroupindex=${order} `;
      str += `id=${element.id} `;
      str += `tag_id=${element.tag_id} `;
      str += `parent_id=${element.parent_id} `;
      str += `${checked}>`;
      str += `<label for="${element.id}">${element.tag_display_text}</label>`;
      str += `</div>`;
    });
    str += "</form>";
    $('#uiSpeakDisplay').append(str);
  });

  // setup tag change listeners
  $('#uiSpeakDisplay input:radio').change(
    function() {
      let speakUIItemIds = ["n"];
      let numOfCategories = roundware.uiConfig.speak.length;
      console.log(`number of tag categories = ${numOfCategories}`);
      // console.log(`number of tag categories: ${numOfCategories}`);
      // after question selected, init recording
      // TODO: really should be after LAST one selected
      if ($(this).attr("name") == "Question") {
        console.log("final tag category has been selected");
        $("#recordInstructions").hide();
        let legalMessage = roundware._project.legalAgreement;
        console.log("legal message: " + legalMessage);
        alertify.confirm('<strong>Legal Agreement</strong>', legalMessage,
          function() {
            // alertify.success('Agree');
            initRecording(roundware);
            startRecordButton.disabled = false;
            $([document.documentElement, document.body]).animate({
              // $("#main").animate({
              scrollTop: $("#location").offset().top - 75
              // scrollTop: "200px"
            }, 500);
          },
          function() {
            // alertify.error('Cancel');
          }).set({
            transition: 'zoom',
            closable: false,
            defaultFocusOff: true
          });
      }
      $("#uiSpeakDisplay input:checked").each(function() {
        speakUIItemIds.push($(this).attr('id'));
      });
      // update();
      console.log(`selected speak uiitem_ids: ${speakUIItemIds}`);
      let currentUIGroupIndex = parseInt($(this).attr('uigroupindex'));
      // for all uiitem/checkbox in FUTURE uigroups, show if parent_id=this.id
      $('.uiitem').each(function(index, element) {
        console.log(`${$(this).attr("uigroupindex")} = (${currentUIGroupIndex} + 1)`);
        // show items in next uigroup with selected uiitem_id or "null" as their parent_id
        if (parseInt($(this).attr("uigroupindex")) == currentUIGroupIndex + 1) {
          if ($.inArray($(this).attr("parent_id"), speakUIItemIds) !== -1 ||
            $(this).attr("parent_id") == "null") {
            let selectedCategory = $(this).attr("name");
            $(this).parent().css("display", "inherit");
            // change display for associated <h2> as well
            $("#uiSpeakDisplay h4").each(function() {
              if ($(this).attr("category") == selectedCategory) {
                $(this).css("display", "inherit");
                $([document.documentElement, document.body]).animate({
                  scrollTop: $(this).offset().top - 100
                }, 500);
              }
            });
          } else {
            $(this).parent().css("display", "none");
          }
        }
      });
    });
}


// Generally we throw user-friendly messages and log a more technical message
function handleError(userErrMsg) {
  console.error("There was a Roundware Error: " + userErrMsg);
}

$(function startApp() {
  // roundware = new RoundwareWebFramework.Roundware(window, {
  //     serverUrl: roundwareServerUrl,
  //     projectId: roundwareProjectId,
  //     geoListenEnabled: false
  //     // apply any speaker filters here
  //     // speakerFilters: {"activeyn": true},
  //     // apply any asset filters here
  //     // assetFilters: {"submitted": true,
  //     //                "media_type": "audio"}
  // });

  // Listen elements
  // streamPlayer    = $("#streamplayer");
  // audioSource     = $("#audiosource");
  // pauseButton     = $("#pause");
  // playButton      = $("#play");
  // killButton      = $("#kill");
  // replayButton    = $("#replay");
  // skipButton      = $("#skip");
  // listenLatitude  = $("#listenLatitude");
  // listenLongitude = $("#listenLongitude");
  // updateButton    = $("#update");

  // Speak elements
  // setBrowserLocation = $("#setBrowserLocation");
  // const speakLatitude = $("#speakLatitude");
  // const speakLongitude = $("#speakLongitude");
  // recordButton       = $("#record");
  // startRecordButton  = $("#startRecordButton");

  roundware.connect().
    then(ready);

  // document.getElementById("initRecorder").addEventListener( "click", function(){
  //   initRecording();
  //   // startRecordButton.prop("disabled",false);
  //   startRecordButton.disabled = false;
  //   initRecorder.disabled = true;
  // });

});

// Google Maps

function setupSpeakMap() {
  // TODO: change to location sensed by browser
  var initialLocation = {
    lat: roundware._project.location.latitude,
    lng: roundware._project.location.longitude
  };

  if (!navigator.geolocation) {
    console.log("no geolocation available");
  }
  else {
    console.log("geolocation available");

    var ipRequest = $.ajax({ url: 'https://json.geoiplookup.io/',});
    var ipApiRequest = ipRequest.done(function(msg) {
      $.ajax('https://ipapi.co/'+ msg.ip +'/json/')
    });

    // request2.done(function(msg) {
    //   console.log('ipapi result', msg);
    // })

    ipApiRequest.done(function(position) {
      initialLocation = {
        lat: position.latitude,
        lng: position.longitude,
        district: position.district,
        city: position.city,
        region: position.region,
        country_name: position.country_name
      };
      $("#findingLocation").hide();
      $("#locationSection").show();
      if (initialLocation.district && initialLocation.district != "") {
        $("#locationText").text(initialLocation.district);
      } else if (initialLocation.city && initialLocation.city != "") {
        $("#locationText").text(initialLocation.city);
      } else if (initialLocation.city && initialLocation.region != "") {
        $("#locationText").text(initialLocation.region);
      } else if (initialLocation.city && initialLocation.country_name != "") {
        $("#locationText").text(initialLocation.country_name);
      } else {
        $("#locationText").text("unknown")
      }
      console.log(`initial browser determined position = ${initialLocation}`);
      console.log(initialLocation);
      speakMap.setCenter({ lat: position.latitude, lng: position.longitude });
      var newPosition = new google.maps.LatLng(position.latitude, position.longitude);
      contributor.setPosition(newPosition);
      document.getElementById("speakLatitude").value = contributor.getPosition().lat();
      document.getElementById("speakLongitude").value = contributor.getPosition().lng();
      speakMap.setZoom(12);
      // do_something(position.coords.latitude, position.coords.longitude);
    });

    preciseLocationButton.addEventListener("click", function() {
      navigator.geolocation.getCurrentPosition(function(position) {
          initialLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
          };
          console.log(`initial browser determined position = ${initialLocation}`);
          console.log(initialLocation);
          speakMap.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
          var newPosition = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
          contributor.setPosition(newPosition);
          document.getElementById("speakLatitude").value = contributor.getPosition().lat();
          document.getElementById("speakLongitude").value = contributor.getPosition().lng();
          speakMap.setZoom(15);
          // do_something(position.coords.latitude, position.coords.longitude);
      });
    });

        // var watchID = navigator.geolocation.watchPosition(function(position) {
        //   console.log("map watcher invoked!");
        //     speakMap.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
        //     var newPosition = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        //     contributor.setPosition(newPosition);
        //     document.getElementById("speakLatitude").value = contributor.getPosition().lat();
        //     document.getElementById("speakLongitude").value = contributor.getPosition().lng();
        // });
    }

    speakMap = new google.maps.Map(document.getElementById('speakMap'), {
        zoom: 2,
        center: initialLocation,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        // mapTypeId: 'hybrid',
        styles: CoronaDiariesMapStyle
    });

    speakMap.addListener('drag', function() {
      contributor.setPosition(speakMap.getCenter())
      document.getElementById("speakLatitude").value = contributor.getPosition().lat();
      document.getElementById("speakLongitude").value = contributor.getPosition().lng();
    })

    var contributor = new google.maps.Marker({
        position: initialLocation,
        map: speakMap,
        draggable: true
    });

    // var watchID = navigator.geolocation.watchPosition(function(position) {
    //   speakMap.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
    //   var newPosition = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    //   contributor.setPosition(newPosition);
    //   document.getElementById("speakLatitude").value = contributor.getPosition().lat();
    //   document.getElementById("speakLongitude").value = contributor.getPosition().lng();
    // });
  // }

  google.maps.event.addListener(contributor, "dragend", function(event) {
    document.getElementById("speakLatitude").value = contributor.getPosition().lat();
    document.getElementById("speakLongitude").value = contributor.getPosition().lng();
    speakMap.setCenter(contributor.getPosition());
  });

  // setup place autocomplete
  var input = document.getElementById('pac-input');
  speakMap.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
  var autocomplete = new google.maps.places.Autocomplete(input);
  autocomplete.bindTo('bounds', speakMap);

  autocomplete.addListener('place_changed', function() {
    // marker = new google.maps.Marker({
    //     map: speakMap,
    //     anchorPoint: new google.maps.Point(0, -29),
    //     draggable: true
    // });
    contributor.setVisible(false);
    var place = autocomplete.getPlace();
    if (!place.geometry) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }
    // If the place has a geometry, then present it on a map.
    if (place.geometry.viewport) {
      speakMap.fitBounds(place.geometry.viewport);
    } else {
      speakMap.setCenter(place.geometry.location);
      speakMap.setZoom(10);
    }
    // contributor.setIcon({
    //     url: place.icon,
    //     size: new google.maps.Size(71, 71),
    //     origin: new google.maps.Point(0, 0),
    //     anchor: new google.maps.Point(17, 34),
    //     scaledSize: new google.maps.Size(35, 35)
    // });
    contributor.setPosition(place.geometry.location);
    contributor.setVisible(true);

    document.getElementById("speakLatitude").value = contributor.getPosition().lat();
    document.getElementById("speakLongitude").value = contributor.getPosition().lng();

    // rww.setMarker(marker);
    // $('input.tag').trigger('change');
  });

  // element.addEventListener("click", function(){ alert("Hello World!"); });
  // document.getElementById("setBrowserLocation").addEventListener( "click", function(){
  //   console.log("setting browser location in map");
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(function(position) {
  //       console.log("current browser position determined");
  //       speakMap.setCenter({lat:position.coords.latitude, lng:position.coords.longitude});
  //       var newPosition = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
  //       contributor.setPosition(newPosition);
  //       document.getElementById("speakLatitude").value = contributor.getPosition().lat();
  //       document.getElementById("speakLongitude").value = contributor.getPosition().lng();
  //     });
  //   } else {
  //     console.log("no location available");
  //   }
  // });
}
